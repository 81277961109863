import { useQuery } from '@tanstack/react-query';
import { getServiceReviewsCount, IServiceReviewsCountParams } from 'common/api/catalog';

export const getGetServiceReviewsCountKey = (params: IServiceReviewsCountParams) => ['getServiceReviewsCount', params];

export const getGetServiceReviewsCountQueryOptions = (params: IServiceReviewsCountParams) => ({
    queryKey: getGetServiceReviewsCountKey(params),
    queryFn: async () => getServiceReviewsCount(params),
});

/**
 * Returns service reviews based on catalogId, 0..1
 */
export const useGetServiceReviewsCountQuery = (params: IServiceReviewsCountParams) =>
    useQuery(getGetServiceReviewsCountQueryOptions(params));
