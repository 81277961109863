import { useQuery } from '@tanstack/react-query';
import { getServiceRating, IServiceRatingParams } from 'common/api/catalog';

export const getGetServiceRatingKey = (params: IServiceRatingParams) => ['getServiceRating', params];

export const getGetServiceRatingQueryOptions = (params: IServiceRatingParams) => ({
    queryKey: getGetServiceRatingKey(params),
    queryFn: async () => getServiceRating(params),
});

/**
 * Returns service rating based on catalogId, 0..1
 */
export const useGetServiceRatingQuery = (params: IServiceRatingParams) =>
    useQuery(getGetServiceRatingQueryOptions(params));
