import { ICategory, IService } from 'common/api';
import { createCachedIndex } from 'common/lib';

type ServicesIndex = Partial<Record<IService['slug'], IService>>;

export const indexServicesBySlug = (categories: ICategory[]): ServicesIndex => {
    const servicesBySlug: ServicesIndex = {};

    for (const category of categories) {
        if (category.slug !== 'popular') {
            for (const service of category.services) {
                servicesBySlug[service.slug] = service;
            }
        }
    }

    return servicesBySlug;
};

const servicesBySlug = createCachedIndex(indexServicesBySlug);

export const selectServiceBySlug = (catalogIndex: ICategory[], serviceSlug: IService['slug']) =>
    servicesBySlug(catalogIndex)[serviceSlug];
