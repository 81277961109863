import type { QueryOptions } from '@tanstack/react-query';
import { ICategory } from 'common/api';
import { getCatalog, IGetCatalogParams } from 'common/api/catalog';

export const getGetCatalogKey = (params: IGetCatalogParams) => ['getCatalog', params];

export const getGetCatalogQueryOptions = (params: IGetCatalogParams): QueryOptions<ICategory[]> => ({
    queryKey: getGetCatalogKey(params),
    queryFn: async () => getCatalog(params),
});
