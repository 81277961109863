import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { ICategory, IService } from 'common/api';
import { IGetCatalogParams } from 'common/api/catalog';
import { getGetCatalogQueryOptions } from '../options/get-get-catalog-query-options';
import { selectCategoryByServiceSlug } from '../selectors/select-category-by-service-slug';

interface IUseGetCategoryByServiceSlugQueryParams extends IGetCatalogParams {
    serviceSlug: IService['slug'] | undefined;
}

export const useGetCategoryByServiceSlugQuery = ({ serviceSlug, ...params }: IUseGetCategoryByServiceSlugQueryParams) =>
    useQuery({
        ...getGetCatalogQueryOptions(params),
        select: React.useCallback(
            (categories: ICategory[]) => {
                if (serviceSlug === undefined) {
                    // eslint-disable-next-line no-console
                    console.error('Parameter <serviceSlug> is not provided');
                    return undefined;
                }

                return selectCategoryByServiceSlug(categories, serviceSlug);
            },
            [serviceSlug],
        ),
        enabled: serviceSlug !== undefined,
    });
