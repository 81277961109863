import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { ICategory, IService } from 'common/api';
import { IGetCatalogParams } from 'common/api/catalog';
import { getGetCatalogQueryOptions } from '../options/get-get-catalog-query-options';
import { selectServiceByCatalogId } from '../selectors/select-service-by-catalog-id';

interface IUseGetServiceByCatalogIdQueryParams extends IGetCatalogParams {
    catalogId: IService['catalog_id'] | undefined;
}

export const useGetServiceByCatalogIdQuery = ({ catalogId, ...params }: IUseGetServiceByCatalogIdQueryParams) =>
    useQuery({
        ...getGetCatalogQueryOptions(params),
        select: React.useCallback(
            (categories: ICategory[]) => {
                if (catalogId === undefined) {
                    // eslint-disable-next-line no-console
                    console.error('Parameter <catalogId> is not provided');
                    return undefined;
                }

                return selectServiceByCatalogId(categories, catalogId);
            },
            [catalogId],
        ),
        enabled: catalogId !== undefined,
    });
