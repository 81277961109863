import { ICategory, IService } from 'common/api';
import { createCachedIndex } from 'common/lib';

type CategoriesIndex = Partial<Record<IService['catalog_id'], ICategory>>;

export const indexCategoriesByCatalogId = (categories: ICategory[]): CategoriesIndex => {
    const categoriesByCatalogId: CategoriesIndex = {};

    for (const category of categories) {
        for (const service of category.services) {
            categoriesByCatalogId[service.catalog_id] = category;
        }
    }

    return categoriesByCatalogId;
};

const categoriesByCatalogId = createCachedIndex(indexCategoriesByCatalogId);

export const selectCategoryByCatalogId = (data: ICategory[], catalogId: IService['catalog_id']) =>
    categoriesByCatalogId(data)[catalogId];
