import { ICategory } from 'common/api';
import { createCachedIndex } from 'common/lib';

type CategoriesIndex = Partial<Record<ICategory['slug'], ICategory>>;

export const indexCategoriesBySlug = (categories: ICategory[]): CategoriesIndex =>
    Object.fromEntries(categories.map((category) => [category.slug, category]));

const categoriesBySlug = createCachedIndex(indexCategoriesBySlug);

export const selectCategoryBySlug = (categories: ICategory[], categorySlug: ICategory['slug']) =>
    categoriesBySlug(categories)[categorySlug];
