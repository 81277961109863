import type { QueryOptions } from '@tanstack/react-query';
import { ICategoryPromotion } from 'common/api';
import { getCatalogPromotion, IGetCatalogPromotionParams } from 'common/api/catalog';

export const getGetCatalogPromotionKey = (params: IGetCatalogPromotionParams) => ['getCatalogPromotion', params];

export const getGetCatalogPromotionQueryOptions = (
    params: IGetCatalogPromotionParams,
): QueryOptions<ICategoryPromotion[]> => ({
    queryKey: getGetCatalogPromotionKey(params),
    queryFn: async () => getCatalogPromotion(params),
});
