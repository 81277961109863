import { useQuery } from '@tanstack/react-query';
import { IGetCatalogParams } from 'common/api/catalog';
import { getGetCatalogQueryOptions } from '../options/get-get-catalog-query-options';
import { selectTaskersCount } from '../selectors/select-taskers-count';

export const useGetCatalogTaskersCountQuery = (params: IGetCatalogParams) =>
    useQuery({
        ...getGetCatalogQueryOptions(params),
        select: selectTaskersCount,
    });
