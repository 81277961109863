import { ICategory, IService } from 'common/api';
import { createCachedIndex } from 'common/lib';

type ServicesIndex = Partial<Record<IService['catalog_id'], IService>>;

export const indexServicesByCatalogId = (categories: ICategory[]): ServicesIndex => {
    const servicesByCatalogId: ServicesIndex = {};

    for (const category of categories) {
        for (const service of category.services) {
            servicesByCatalogId[service.catalog_id] = service;
        }
    }

    return servicesByCatalogId;
};

const servicesByCatalogId = createCachedIndex(indexServicesByCatalogId);

export const selectServiceByCatalogId = (catalogIndex: ICategory[], catalogId: IService['catalog_id']) =>
    servicesByCatalogId(catalogIndex)[catalogId];
