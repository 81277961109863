import { useQuery } from '@tanstack/react-query';
import { getSubServicesByCatalogId, IGetSubServicesByCatalogIdParams } from 'common/api/service';

export const getGetSubServicesKey = (params: IGetSubServicesByCatalogIdParams) => ['getSubServices', params];

export const getGetSubServicesQueryOptions = ({ locale, catalogId }: IGetSubServicesByCatalogIdParams) => ({
    queryKey: getGetSubServicesKey({ locale, catalogId }),
    queryFn: async () => getSubServicesByCatalogId({ locale, catalogId }),
});

export const useGetSubServicesQuery = (params: IGetSubServicesByCatalogIdParams) =>
    useQuery(getGetSubServicesQueryOptions(params));
