import { ICategory, IService } from 'common/api';
import { createCachedIndex } from 'common/lib';

type CategoriesIndex = Partial<Record<IService['slug'], ICategory>>;

export const indexCategoriesByServiceSlug = (categories: ICategory[]): CategoriesIndex => {
    const categoriesByServiceSlug: CategoriesIndex = {};

    for (const category of categories) {
        if (category.slug !== 'popular') {
            for (const service of category.services) {
                categoriesByServiceSlug[service.slug] = category;
            }
        }
    }

    return categoriesByServiceSlug;
};

const categoriesByServiceSlug = createCachedIndex(indexCategoriesByServiceSlug);

export const selectCategoryByServiceSlug = (data: ICategory[], serviceSlug: IService['slug']) =>
    categoriesByServiceSlug(data)[serviceSlug];
