import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { ICategory, IService } from 'common/api';
import { IGetCatalogParams } from 'common/api/catalog';
import { getGetCatalogQueryOptions } from '../options/get-get-catalog-query-options';
import { selectServiceBySlug } from '../selectors/select-service-by-slug';

interface IUseGetServiceBySlugQueryParams extends IGetCatalogParams {
    serviceSlug: IService['slug'] | undefined;
}

export const useGetServiceBySlugQuery = ({ serviceSlug, ...params }: IUseGetServiceBySlugQueryParams) =>
    useQuery({
        ...getGetCatalogQueryOptions(params),
        select: React.useCallback(
            (categories: ICategory[]) => {
                if (serviceSlug === undefined) {
                    // eslint-disable-next-line no-console
                    console.error('Parameter <serviceSlug> is not provided');
                    return undefined;
                }

                return selectServiceBySlug(categories, serviceSlug);
            },
            [serviceSlug],
        ),
        enabled: serviceSlug !== undefined,
    });
